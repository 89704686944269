

import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class DeepAnalysisHotelTransitionPage extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(DeepCompsetAnalysisServiceS) private deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    @Prop({
        required: true,
        type: Number,
    })
    private hotelId!: number;

    @Watch('hotelId', { immediate: true })
    async initCurrentHotel(hotelId: number): Promise<void> {
        if (!this.userService.storeState.user) {
            return;
        }

        const isNeedToSwitch = this.userService.currentHotelId !== hotelId;

        if (isNeedToSwitch) {
            await this.userService.switchCurrentHotel(hotelId);
        }

        this.deepCompsetAnalysisService.loading.reset();
        const isChainOrCluster = this.$route.path.includes('hotels') || this.$route.path.includes('chain') || this.$route.path.includes('cluster');

        if (!isChainOrCluster && (this.userService.isChainUser || this.userService.isClusterUser)) {
            this.userService.storeState.user = {
                ...this.userService.storeState.user,
                viewAs: USER_LEVELS.HOTEL,
            };
        }
    }
}
